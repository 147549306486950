import React from 'react';
import PropTypes from 'prop-types';
import { handleTabAction } from '../../utils/helpers';
import { getLocalDataObject } from '../../utils/Storage';

const MembershipPlanSummary = ({ handleBilling, handleTabChange }) => {
  const selectedMembershipPlan = getLocalDataObject('selectedMembershipPlan');

  const handleNext = (e) => {
    e.preventDefault();
    handleTabAction('billing', 'questionnaire');
    handleBilling('questionnaire');
  };

  const handleBack = () => {
    handleTabChange('profile');
    handleTabAction('billing', 'profile');
  };
  return (
    <>
      <div className="plan-summary account_height">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="s-m">
                <h4>Plan Summary </h4>
              </div>
              <h5 className="h5class">{selectedMembershipPlan?.packageName}</h5>
              <div className="c-p mt-0">
                <table className="table fee-t">
                  <tbody>
                    <tr>
                      <td>{selectedMembershipPlan?.name}</td>
                      <th>( - ${selectedMembershipPlan?.price} )</th>
                    </tr>
                    <tr>
                      <td>Activation Fee</td>
                      <th>$1.99</th>
                    </tr>
                    <tr>
                      <td>Tax</td>
                      <th>$0.00</th>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Total</td>
                      <th>$1.99</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="button-back my-5">
                <a className="back-text" onClick={handleBack}>
                  Back
                </a>
                <button
                  type="button"
                  onClick={handleNext}
                  className="theme-button next-btn mobile-button-view"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MembershipPlanSummary.propTypes = {
  handleBilling: PropTypes.func,
  handleTabChange: PropTypes.func,
};

export default MembershipPlanSummary;

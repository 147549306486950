import React, { useState } from 'react';
import { generatePrice, handleTabAction } from '../../utils/helpers';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ErrorBanner from '../ErrorBanner';
import PropTypes from 'prop-types';
import { getLocalData, getLocalDataObject } from '../../utils/Storage';

const ReviewConfirm = ({ handleTabChange }) => {
  const { personalDetail, billingDetail } = useSelector(
    (state) => state.signup
  );
  const isMembership = getLocalData('isMembershipActivate');

  const selectedMembershipPlan = getLocalDataObject('selectedMembershipPlan');

  const [termCondition, setTermCondition] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const navigate = useNavigate();
  const handleBack = () => {
    handleTabChange('questionnaire');
    handleTabAction('review', 'questionnaire');
  };

  const handlePay = () => {
    if (termCondition) {
      navigate('/checkouts');
    } else {
      setShowErrorDetail({
        isError: false,
        message: 'Please agree to Terms & Conditions.',
      });
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setShowErrorDetail({
          isError: true,
          message: '',
        });
      }, 3000);
    }
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  return (
    <>
      <div className="join-form-user">
        <div className="container-fluid">
          {showError && (
            <ErrorBanner error={showErrorDetail} handleClose={handleClose} />
          )}
          <div className="row">
            <h4 className="billing-plan pt-2">REVIEW & CONFIRM</h4>
            <div className="user-all-id">
              <div className="user-tab">
                <div className="m-d">
                  <h3>Member Details</h3>
                  <p className="full-address-user">
                    <span className="user-name-b">
                      {personalDetail?.name
                        ? personalDetail?.name
                        : personalDetail?.fullName}
                    </span>
                    <br />
                    {personalDetail?.email}
                    <br />
                    {personalDetail?.phoneNumber
                      ? personalDetail?.phoneNumber
                      : personalDetail?.telephone}
                    <br />
                    <br />
                    {personalDetail.address +
                      ', ' +
                      personalDetail.city +
                      ', ' +
                      personalDetail.state}
                    <br />
                    {personalDetail.zipCode
                      ? personalDetail.zipCode
                      : personalDetail.zipcode}
                  </p>
                </div>
                <table className="table mobile-responsive tb">
                  <thead className="d-none">
                    <tr className="table-user-line">
                      <th
                        style={{
                          verticalAlign: 'baseline',
                          whiteSpace: 'nowrap',
                          paddingLeft: '0px',
                        }}
                      >
                        <p className="user-name">Member Details</p>
                      </th>
                      <th>
                        <p className="full-address-user">
                          <span>
                            {personalDetail?.name
                              ? personalDetail?.name
                              : personalDetail?.fullName}{' '}
                            {/* {personalDetail?.lastName
                              ? personalDetail?.lastName
                              : personalDetail?.last_name} */}
                          </span>
                          <br />
                          {personalDetail?.email}
                          <br />
                          {personalDetail?.phoneNumber
                            ? personalDetail?.phoneNumber
                            : personalDetail?.telephone}
                          <br />
                          <br />
                          {personalDetail.address +
                            ' ' +
                            personalDetail.city +
                            ', ' +
                            personalDetail.state}
                          <br />
                          {personalDetail.zipCode
                            ? personalDetail.zipCode
                            : personalDetail.zipcode}
                        </p>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="table-user-line d-none">
                      <td
                        style={{
                          verticalAlign: 'baseline',
                          whiteSpace: 'nowrap',
                          paddingLeft: '0px',
                        }}
                        className="screen-first"
                      >
                        <p className="user-name">Member Details</p>
                      </td>
                      <td data-label="Member Details" className="top-list">
                        <p className="full-address-user">
                          <span>
                            {personalDetail?.name
                              ? personalDetail?.name
                              : personalDetail?.fullName}{' '}
                            {/* {personalDetail?.lastName
                              ? personalDetail?.lastName
                              : personalDetail?.last_name} */}
                          </span>
                          <br />
                          {personalDetail?.email}
                          <br />
                          {personalDetail?.phoneNumber
                            ? personalDetail?.phoneNumber
                            : personalDetail?.telephone}
                          <br />
                          <br />
                          {personalDetail.address +
                            ', ' +
                            personalDetail.city +
                            ', ' +
                            personalDetail.state}
                          <br />
                          {personalDetail.zipCode
                            ? personalDetail.zipCode
                            : personalDetail.zipcode}
                        </p>
                      </td>
                      <td className="screen-first"></td>
                    </tr>
                    {/* <tr>
                    <td>
                      <p className="table-date-title">Additional Associate</p>
                    </td>
                    <td>
                      <p className="yearly-list-user">
                        {billingDetail.isAddPeople
                          ? billingDetail?.peopleDetail?.firstName +
                            ' ' +
                            billingDetail?.peopleDetail?.lastName
                          : '-'}
                      </p>
                    </td>
                    <td>
                      <p className="yearly-list-user"></p>
                    </td>
                  </tr> */}
                    <tr>
                      <td className="screen-first">
                        <p className="table-date-title">
                          {isMembership
                            ? selectedMembershipPlan?.name
                            : 'Plans'}
                        </p>
                      </td>
                      <td data-label="Plans">
                        {isMembership ? (
                          <p className="yearly-list-user plan-list-price">
                            {selectedMembershipPlan?.packageName}
                          </p>
                        ) : (
                          <p className="yearly-list-user plan-list-price">
                            {
                              billingDetail?.selectedPackage?.billingOption
                                ?.planName
                            }
                            <span>
                              {' '}
                              {generatePrice(
                                billingDetail?.selectedPackage?.billingOption
                                  ?.planPrice
                              )}
                            </span>
                          </p>
                        )}
                      </td>
                      <td className="screen-first">
                        {isMembership ? (
                          <p className="dollar">
                            ( - ${selectedMembershipPlan.price} )
                          </p>
                        ) : (
                          <p className="dollar">
                            {generatePrice(
                              billingDetail?.selectedPackage?.billingOption
                                ?.planPrice
                            )}
                            {billingDetail?.selectedPackage?.billingOption
                              ?.priceFrequency === 'Monthly'
                              ? ' /month'
                              : billingDetail?.selectedPackage?.billingOption
                                    ?.priceFrequency === 'Yearly'
                                ? ' /year'
                                : ' /one-time'}
                          </p>
                        )}
                      </td>
                    </tr>
                    {isMembership ? (
                      <tr>
                        <td data-label="Activation Fee" className="top-list-2">
                          <p className="table-date-title screen-first">
                            Activation Fee
                          </p>
                          <p className="yearly-list-user plan-list-price d-lg-none">
                            -<span>$1.99 </span>
                          </p>
                        </td>
                        <td className="screen-first">
                          <p className="yearly-list-user">-</p>
                        </td>
                        <td className="screen-first">
                          <p className="dollar">$1.99</p>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          data-label="One Time Registration Fee"
                          className="top-list-2"
                        >
                          <p className="table-date-title screen-first">
                            One Time Registration Fee
                          </p>
                          <p className="yearly-list-user plan-list-price d-lg-none">
                            -<span>$0.00 </span>
                          </p>
                        </td>
                        <td className="screen-first">
                          <p className="yearly-list-user">-</p>
                        </td>
                        <td className="screen-first">
                          <p className="dollar">$0.00</p>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td data-label="Tax" className="top-list-2">
                        <p className="table-date-title screen-first">Tax</p>
                        <p className="yearly-list-user plan-list-price d-lg-none">
                          -<span>$0.00</span>
                        </p>
                      </td>
                      <td className="screen-first">
                        <p className="yearly-list-user">-</p>
                      </td>
                      <td className="screen-first">
                        <p className="dollar">$0.00</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="screen-first">
                        <p className="table-date-title">Total Due Today</p>
                      </td>
                      <td className="screen-first">
                        <p className="yearly-list-user">-</p>
                      </td>
                      <td data-label="Total Due Today">
                        {isMembership ? (
                          <p className="dollar-1 yearly-list-user plan-list-price">
                            <span>-</span>
                            $1.99
                          </p>
                        ) : (
                          <p className="dollar-1 yearly-list-user plan-list-price">
                            <span>-</span>
                            {generatePrice(billingDetail?.total)}
                          </p>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(e) => setTermCondition(e.target.checked)}
                  id="gridCheck"
                  checked={termCondition}
                />
                <label className="form-check-label" htmlFor="gridCheck">
                  By proceeding you are agreeing to The Caliber Card&apos;s
                  Terms & Conditions mentioned in{' '}
                  <Link to="/terms-conditions" target="_blank">
                    Subscribing Member Agreement
                  </Link>{' '}
                  . I agree to enter into a relationship with The Range Network
                  to provide the subscription plan as selected above.
                </label>
              </div>
            </div>
            <div className="button-back mt-3 new-add-button">
              <a onClick={handleBack} className="back-text d-none d-md-block">
                Back
              </a>
              <a className="theme-button d-none d-md-block" onClick={handlePay}>
                Pay now
              </a>
            </div>
            <div className="button-back mt-3 d-block d-md-none new-add-button">
              <a
                className="button-golden px-5 mt-4 login-button"
                onClick={handlePay}
              >
                Pay now
              </a>
              <a onClick={handleBack} className="back-text">
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ReviewConfirm.propTypes = {
  handleTabChange: PropTypes.func,
};

export default ReviewConfirm;

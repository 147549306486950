import React, { useEffect, useRef, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import { postApi } from '../utils/api';
import { user_subscribe } from '../utils/endpoint';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PackagePlan from '../components/PackagePlan';
import FindRangeMap from '../components/FindRangeMap';
import { Link } from 'react-router-dom';

const Index = () => {
  const [contactUsForm] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    comment: '',
  });

  const [isResponse, setIsResponse] = useState(null);
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('name', email);
    await postApi(user_subscribe, formdata)
      .then((res) => {
        if (res.data.status) {
          setEmail('');
          setIsResponse(true);
          setResponseMessage(
            "Thanks for subscribing us. We'll get back to you as soon as possible."
          );
        }
      })
      .catch((e) => {
        setIsResponse(false);
        setResponseMessage(
          e?.response?.data?.errors?.email[0]
            ? e?.response?.data?.errors?.email[0]
            : e?.response?.data?.message
        );
      });
  };

  const handleInputChange = (e) => {
    setIsResponse(false);
    setResponseMessage('');
    setEmail(e.target.value);
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      await handleSubmit(e);
    } else {
      form.reportValidity();
    }
  };

  useEffect(() => {
    localStorage.removeItem('joinEmail');
    localStorage.removeItem('selectedPlanType');
    localStorage.removeItem('selectedPlan');
    localStorage.removeItem('userData');
    localStorage.removeItem('selectedMembershipPlan');
    localStorage.removeItem('isMembershipActivate');
  }, []);

  return (
    <>
      <MainLayout>
        <div className="post-log">
          <div className="container-fluid">
            <div className="row">
              <div className="hero-section">
                <h3>IF YOU LOVE THE JOURNEY,</h3>
                <h3>YOU’RE GONNA LOVE THE DESTINATION.</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="liberty-outer liberty">
          <div className="container">
            <div className="liberty-text">
              <h4 className="sub-title-2">What is the Caliber Card?</h4>
              <p className="bear-text">
                Enjoy the thrill of the range wherever you go with the exclusive
                benefits of the Caliber Club! The nationwide Caliber Card
                Membership, brought to you by the Range Network, enables you to
                visit any participating retail gun range in the United States
                through membership at your “home” range. Your home location is
                the facility where you enrolled as a member or where it is part
                of your membership dues.
              </p>
            </div>
          </div>
        </div>
        <PackagePlan />
        <div className="responsibly d-none">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6 g-0  order-md-2">
                <div className="gun-images ">
                  <img src="../../images/guns.jpg" alt="" className="w-100" />
                </div>
              </div>
              <div className="col-md-6 order-md-1">
                <div className="responsibly-user-text">
                  <h3 className="sub-title">
                    PROTECT YOUR FAMILY, RESPONSIBLY.
                  </h3>
                  <p className="sub-text-user">
                    Right To Bear offers Elite protection along with many other
                    great benefits. Our plans offer more than just legal
                    reimbursement to secure your peace of mind.
                  </p>

                  <div className="list-pointer">
                    <ul>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Civil Defense</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Criminal Defenses</p>
                      </li>
                      <li className="d-flex align-items-start">
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid list-image pt-2"
                        />
                        <p>
                          All states in the United States except New York, New
                          Jersey and Washington,
                        </p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Psychology Support</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Expert Witness Defense</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Gun Replacement</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Accidental Discharge</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Self Defense Training Videos</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Discount to Industry Partners</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>Spousal Coverage *</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Minor Children Defense *</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Bail Bond Coverage ($100,000) max *</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>24/7 Attorney Answered Hotline</p>
                      </li>
                    </ul>
                    <a
                      href="/coverage"
                      className="theme-button button-white mobile-width"
                      style={{
                        padding: '18px 80px',
                        border: '1px solid #b6632f',
                      }}
                    >
                      JOIN NOW
                    </a>
                    <p className="list-available">
                      *Available as an optional add-on
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shopify-section-template d-none">
          <div className="container">
            <div className="row">
              <div className="template">
                <div className="shopify mb-4">
                  <h3 className="section-heading">STILL HAVE QUESTIONS?</h3>
                  <p className="email-text">
                    Contact our customer service team at{' '}
                    <a
                      href="mailto:info@mycalibercard.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@mycalibercard.com
                    </a>
                  </p>
                  {isResponse && (
                    <>
                      <div className="check-img-and-text">
                        <img src="../images/check_green.svg" alt="" />
                        &nbsp;
                        <p>{responseMessage}</p>
                      </div>
                    </>
                  )}
                </div>

                <form id="earlyAccessForm" ref={formRef}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="name"
                          name="name"
                          className="form-control form-input"
                          placeholder="Name"
                          value={contactUsForm.name}
                          onChange={handleInputChange}
                          required
                        />
                        <label htmlFor="floatingInput">Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          name="email"
                          className="form-control form-input"
                          placeholder="email"
                          value={contactUsForm.email}
                          onChange={handleInputChange}
                          required
                        />
                        <label>Email*</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          className="form-control form-input"
                          placeholder="Phone Number"
                          name="phoneNumber"
                          type="tel"
                          maxLength="10"
                          onKeyPress={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (!regex.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={contactUsForm.phoneNumber}
                          onChange={handleInputChange}
                        />
                        <label>Phone Number</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control form-input"
                          placeholder="Leave a comment here"
                          style={{ height: '100px' }}
                          name="comment"
                          value={contactUsForm.comment}
                          onChange={handleInputChange}
                        ></textarea>
                        <label htmlFor="floatingTextarea2">Comments</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        form="earlyAccessForm"
                        className="from-button"
                        onClick={handleManualSubmit}
                      >
                        Submit
                      </button>
                    </div>
                    <p className="form-text-last">
                      By completing this form, I have read and acknowledged the
                      Privacy Statement and agree that Right to Bear may contact
                      me at the email address or phone number above
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="apply-21">
          <div className="container">
            <div className="row">
              <p>Conditions Apply | Must be at least 21 years old to join</p>
            </div>
          </div>
        </div>
        <div className="caliber-card">
          <div className="container">
            <div className="row">
              <span className="brand-emblem">
                <img
                  src="../images/brand_emblem.svg"
                  alt="brand_emblem_logo"
                  width={119}
                  height={131}
                />
              </span>
              <p>
                Caliber Card Members get access to exclusive offers and access
                to unique events at each of our participating partner Ranges.
                Walking into a partner range is like entering an exciting world
                of new experiences, fun and community.
              </p>
            </div>
          </div>
        </div>
        <FindRangeMap />
        <div className="liberty-outer about-net">
          <div className="container">
            <div className="liberty-text">
              <h4 className="sub-title-2">ABOUT THE RANGE NETWORK</h4>
              <p className="bear-text">
                Every visit and each shooting activity to one of our network
                ranges is designed to bring friends and family together to share
                an exhilarating experience and create lasting memories. When
                planning your next family vacation, road trip with friends or
                business trip, explore each range in our network for more
                information on how Caliber Card Members can benefit while
                traveling.
              </p>
            </div>
          </div>
        </div>
        <div className="lear-more">
          <img
            src="../images/the_range_network_logo.svg"
            alt="the_range_network_logo"
          />
          <br />
          <Link
            to="https://therangenetwork.com/"
            className="theme-button"
            target="_blank"
          >
            Learn More
          </Link>
        </div>
      </MainLayout>
    </>
  );
};

export default Index;

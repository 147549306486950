import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPackage } from '../redux/features/packages/packageSlice';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const PackagePlan = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.package.packages);
  const navigate = useNavigate();
  const { personalDetail } = useSelector((state) => state.signup);
  // const [registrationFee, setRegistrationFee] = useState(0);
  const [isPlan, setIsPlan] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchPackage());
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      data.map((item) => {
        {
          item.packages.map((package_data) => (
            <>
              {package_data.package_prices
                ?.filter(
                  (price_record) => price_record.price_frequency === 'One-Time'
                )
                .map(
                  (price) => console.log(price.price, 'Price')
                  // setRegistrationFee(price.price)
                )}
            </>
          ));
        }
      });
    }
  }, [data]);

  const handlePlan = (data) => {
    // let newData = data;
    // newData.selected_plan = isPlan ? 'Monthly' : 'Yearly';
    // console.log(newData);
    let isLifeTime = '';
    data.packages.map((package_data) => (
      <>
        {package_data.package_prices
          ?.filter(
            (price_record) => price_record.price_frequency === 'Lifetime'
          )
          .map((price) => {
            isLifeTime = price.price_frequency;
          })}
      </>
    ));
    localStorage.setItem('selectedPlan', JSON.stringify(data));
    localStorage.setItem('selectedTerm', !isPlan ? 'month' : 'year');

    if (isLifeTime !== '') {
      localStorage.setItem('selectedPlanType', 'Lifetime');
    } else {
      localStorage.setItem('selectedPlanType', !isPlan ? 'Monthly' : 'Yearly');
    }

    if (
      personalDetail?.subscriptions?.length === 0 &&
      Cookies.get('isSubscribed') === undefined
    ) {
      localStorage.setItem('joinEmail', personalDetail?.email);
      navigate('/plan-checkout');
    } else {
      navigate('/joinform');
    }
  };

  // Renders the price details for a given package based on the pricing plan.
  const renderPriceDetails = (package_data, isPlan) => {
    return package_data.package_prices
      ?.filter((price_record) => {
        const isMonthlyOrLifetime =
          price_record.price_frequency === 'Monthly' ||
          price_record.price_frequency === 'Lifetime';
        const isYearlyOrLifetime =
          price_record.price_frequency === 'Yearly' ||
          price_record.price_frequency === 'Lifetime';
        return isPlan
          ? isYearlyOrLifetime && !price_record.is_prepaid
          : isMonthlyOrLifetime;
      })
      .map((price, id) => (
        <div className="price-dcpt" key={id}>
          <h5 className="description-price">
            ${price.price + ' '}
            <span className="font-c">{price.description}</span>
            <br />
            <span>
              {isPlan
                ? 'Unlimited access to our indoor, outdoor and shotgun ranges during business hours.'
                : package_data?.description}
            </span>
          </h5>
        </div>
      ));
  };

  // Renders the card image based on the membership type.
  const renderCardImage = (item) => {
    const images = {
      Annual: '../images/Caliber_Cards_Card.png',
      GOLD: '../images/Caliber_Cards_Card.png',
      Lifetime: '../images/Caliber_Cards_Lifetime.png',
    };
    // Return the corresponding image if it exists
    return item.name in images ? (
      <div className="card-img-price">
        <img
          src={images[item.name]}
          className="card-img-top img-fluid"
          alt={`${item.name} Member Card`}
        />
      </div>
    ) : null;
  };

  // Renders the plan cards based on the provided data and plan type.
  const renderPlanCards = (data, isPlan) => {
    return data.map((item, index) => {
      const validPackages = item.packages.filter((package_data) =>
        package_data.package_prices?.some(
          (price_record) => !price_record.is_prepaid
        )
      );

      // Only render the card if there is at least one valid package
      if (validPackages.length > 0) {
        return (
          <div className="card" key={index}>
            {validPackages.map((package_data, ind) => (
              <React.Fragment key={ind}>
                {renderPriceDetails(package_data, isPlan)}
              </React.Fragment>
            ))}
            {renderCardImage(item)}
            <div className="card-body">
              <h5>Benefits Include:</h5>
              <ul>
                {item.features?.map((feature) => (
                  <li key={feature.id}>{feature.name}</li>
                ))}
              </ul>
              <p>(**Registration fee: $0)</p>
              <button
                className="theme-button select-plan-btn"
                onClick={() => handlePlan(item)}
              >
                Select Plan
              </button>
            </div>
          </div>
        );
      }
      return null; // Return null if no valid package is found
    });
  };
  return (
    <>
      <div className="plan-select-mon-yer">
        <div className="form-check form-switch">
          <label
            className="form-check-label m"
            htmlFor="monthly"
            style={{ textAlign: 'left' }}
          >
            Monthly
          </label>
          <input
            className={`form-check-input ${!isPlan ? 'month' : 'yearly'}`}
            type="checkbox"
            id={!isPlan ? 'annually' : 'monthly'}
            onChange={() => setIsPlan(!isPlan)}
            checked={isPlan}
          />
          <label className="form-check-label" htmlFor="annually">
            Annually
          </label>
        </div>
      </div>
      <div
        className={
          data && data.length === 3 ? 'card-price ' : 'card-price pd-outer'
        }
      >
        {data && <>{renderPlanCards(data, isPlan)}</>}
      </div>
    </>
  );
};

export default PackagePlan;

import React, { useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { emailRegExp } from '../utils/common';
import { checkEmail } from '../utils/endpoint';
import { postApi } from '../utils/api';
import { ThreeDots } from 'react-loader-spinner';
import ErrorBanner from './ErrorBanner';

const JoinForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isTermAndCondition, setIsTermAndCondition] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });
  const [showError, setShowError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ email }, { abortEarly: false });

      if (!isTermAndCondition) {
        setShowErrorDetail({
          isError: false,
          message: 'Please agree to Terms & Conditions.',
        });
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
          setShowErrorDetail({
            isError: true,
            message: '',
          });
        }, 3000);
        return;
      }

      setIsLoading(true);
      postApi(checkEmail, { email: email })
        .then((res) => {
          setError('');
          if (res.data?.data) {
            localStorage.setItem('joinEmail', email);
            navigate('/plan-checkout');
          } else {
            navigate('/login', {
              state: {
                dataStateMessage: `There is already an account associated with "${email}", Please try to login. If you don't remember your password, use Forget Password`,
              },
            });
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e.response?.data);
          setIsLoading(false);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setError(validationErrors);
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };
  return (
    <>
      {showError && (
        <ErrorBanner error={showErrorDetail} handleClose={handleClose} />
      )}
      <MainLayout>
        <div className="submit login-height">
          <h3>Nice Choice! Lets Get Started.</h3>
          <div className="floating-group">
            <input
              type="mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={
                'form-control mt-4' +
                (error.email && ' error-input-border-red ')
              }
              required
              onKeyPress={handleKeyPress}
            />
            <label htmlFor="name" className="label-text">
              What’s your email address?
            </label>
            {error && error.email && (
              <p style={{ color: 'red' }}>{error.email}</p>
            )}
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="gridCheck"
              checked={isTermAndCondition}
              onChange={(e) => setIsTermAndCondition(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="gridCheck">
              By clicking the checkbox, I represent that I am over 18 years of
              age and agree to accept our{' '}
              <Link to="/terms-conditions" target="_blank">
                Terms & Conditions
              </Link>{' '}
              and{' '}
              <Link to="/privacy" target="_blank">
                Privacy Policy
              </Link>{' '}
              . I agree The Range Network can keep me informed by sending
              personalized emails and SMS messages about products and services.
              See our Privacy Policy for details or to opt-out at any time.
            </label>
          </div>

          <div className="text-center mt-3">
            <a
              onClick={handleSubmit}
              className={
                'theme-button login-button' + (isLoading ? ' disabled-btn' : '')
              }
            >
              {isLoading ? (
                <ThreeDots
                  height="28"
                  width="128"
                  radius="1"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                'get started'
              )}
            </a>
          </div>
          <p className="account">
            Already have an account? <a href="/login">Log In</a>
          </p>
        </div>
      </MainLayout>
    </>
  );
};

export default JoinForm;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
});

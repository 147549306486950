import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <>
      <MainLayout>
        <div className="privacy-terms">
          <div className="container">
            <div className="row">
              <div className="conditions">
                <h3 className="my-5">Caliber Card Privacy Policy</h3>
                <h3 className="mb-3">Last updated: January 15, 2025</h3>
                <p>
                  <strong>Caliber Card</strong> is a platform where Users can
                  support and engage with third-party entities. This Privacy
                  Policy (this “Policy”) is designed to protect and respect the
                  privacy of all users of our platform. It is part of our Terms
                  of Use and is a testament to our commitment to your privacy.
                </p>
                <p>
                  <strong>
                    Caliber Card operates within Texas and adheres to the Texas
                    Data Privacy and Security Act (TDPSA){' '}
                  </strong>{' '}
                  Section 541.002 of the TDPSA.
                </p>
                <p>
                  The definition of Caliber Card User falls within the personal
                  data definition, which is any information “linked or
                  reasonably linkable to an identified or identifiable
                  individual. “The definition of personal information covers
                  pseudonymous data when “the data is used by a controller or
                  processor in conjunction with additional information that
                  reasonably links the data to an identifiable individual. The
                  term does not include de-identified data or publicly available
                  information.” Caliber Card does not sell your private
                  information as listed in this Policy.
                </p>
                <p>
                  <strong>
                    Note: <u>Sec. 541.107 states that a person </u>
                  </strong>{' '}
                  covered by the above definitions &quot;may not engage in the
                  sale of personal data that is sensitive data without receiving
                  prior consent from the consumer.&quot;
                </p>
                <p>
                  Upon registration, by accepting the Caliber Card Privacy and
                  Terms of Service, you acknowledge that you are OPTING OUT of
                  specific requirements. Your acceptance does not limit your
                  rights as an individual Caliber Card member. You still have
                  the right to access, portability, correct, or delete records
                  (right to request deletion), and you are protected against
                  discrimination for exercising privacy rights. Sensitive data
                  can only be collected with your unambiguous consent.
                </p>

                <h3>Opt-In and Opt-Out Acknowledgment</h3>
                <p>
                  By registering for ShotPro/Caliber Card, you are required to
                  select one of the following options:
                </p>
                <h6>Opting Out</h6>
                <ul className="browser">
                  <li>
                    If you select Opting Out during registration, an account
                    will NOT be created.
                  </li>
                  <li>
                    You will not have access to the ShotPro App or ShotPro.com,
                    and you will be unable to participate in any features or
                    services offered by ShotPro, including but not limited to
                    purchases, membership benefits, exclusive discounts, or
                    merchandise.{' '}
                  </li>
                </ul>
                <h6>Opting In</h6>
                <ul className="browser">
                  <li>
                    If you select Opting In during registration, you acknowledge
                    and agree to the creation of a ShotPro account.
                  </li>
                  <li>
                    By opting in, you gain access to all features and services
                    provided by ShotPro, including:
                    <ul className="browser">
                      <br />
                      <li>
                        The ability to make purchases through the ShotPro App or
                        ShotPro.com.
                      </li>
                      <li>
                        Participation in exclusive discounts and promotions.
                      </li>
                      <li>Access to ShotPro membership benefits.</li>
                      <li>
                        The opportunity to purchase exclusive merchandise.
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  By selecting Opting In, you also agree to the terms outlined
                  in the ShotPro Privacy Policy and Terms of Use, including the
                  collection, processing, and storage of your data as described
                  therein.
                </p>
                <p>
                  Caliber Card is a U.S. company with headquarters in Texas. By
                  using our platform, you agree that the personal information
                  you provide directly to us or that we collect through your use
                  of the platform may be transferred to and stored in the United
                  States and handled as described in this Policy.
                </p>
                <h3>Information You Provide Through Your Account</h3>
                <p>
                  You provide this information through various text fields, such
                  as your name, payment information, and benefits. The
                  information we collect differs depending on whether you make
                  an account or become a Caliber Card User.
                </p>

                <ul className="browser">
                  <li>First and Last Name </li>
                  <li>Email Address </li>
                  <li>Username </li>
                  <li>Password</li>
                  <li>State and Country of Residence </li>
                </ul>
                <p>
                  We store this information and any comments or messages you
                  submit on Caliber Card and verified through biometrics,
                  photograhs, and document authentication.
                </p>

                <h3>User</h3>
                <p>
                  A User joins Caliber Card&apos;s platform as a firearms user
                  to support the 2nd Amendment. As a User, you must provide your
                  payment information to Stripe, our payment processor. You can
                  see Stripe&apos;s privacy policy. We collect and process
                  information about the Third-party entities you support, the
                  level at which you help them, what benefits you receive, and
                  how often you support them.
                </p>

                <h3 className="text-center">
                  Additional Information We Collect
                </h3>

                <h6>Automatically Collected Information</h6>
                <p>
                  We collect information automatically as you navigate the site
                  or through our third-party analytics providers. We may store
                  usage information such as the device to access Caliber Card,
                  your operating system, browser type, IP address, device ID,
                  the pages you visit or request, links clicked, referring
                  sites, user interactions, and search terms. We also derive
                  your location from your self-disclosed country, IP address,
                  IPA variations, and payment card. You may also see the Stripe
                  Financial privacy policy here at{' '}
                  <Link
                    to="https://stripe.com/legal/privacy-center"
                    target="_blank"
                  >
                    https://stripe.com/legal/privacy-center
                  </Link>
                  .
                </p>
                <h6>User Surveys</h6>
                <p>
                  From time to time, we may solicit your participation in user
                  surveys. We may ask you for demographic information, including
                  information related to your gender, ethnicity, race, age,
                  sexual orientation, earnings, and accessibility, to understand
                  better and serve our user base, which you may elect to share
                  or not. We collect such information to ensure we provide fair
                  and equal access to our services and to showcase Third-party
                  entities to users for greater discoverability. To the extent
                  that you participate, we will store your survey responses.
                </p>
                <h6>Event Information</h6>
                <p>
                  At live and online events, we may request information from
                  you. This is done to better tailor the event experience to
                  those in attendance. This information may include your name,
                  email address, payment details, mobile phone number and QR
                  code, demographic data, social media and other online accounts
                  you maintain, details around the types of works you create,
                  and survey or feedback responses.
                </p>
                <h6>Blogs</h6>
                <p>
                  We may collect and store your email address, comments, and
                  profile information from our blog sites to better understand
                  your interests and improve our services to you.
                </p>

                <h3>Information Collected by Third Parties</h3>
                <h6>Cookies</h6>
                <p>
                  We use cookies on our website that collect information to
                  allow third parties and us to understand your use of Caliber
                  Card. Please see our{' '}
                  <Link to="https://picklejar.com/cookie-policy/">
                    cookie policy{' '}
                  </Link>
                  for more details on using cookies on Caliber Card.
                </p>

                <h6>Social Media Features and Widgets</h6>
                <p>
                  We use social media features, including the Facebook Like
                  button and widgets like the Share button or similar
                  interactive mini-programs that run on Caliber Card. If you
                  provide us with your social media information, we may use it
                  to interact with you on these social networks. These features
                  may collect your IP address and other personal data, including
                  which page you are visiting on our site, and may set a cookie
                  to enable the feature to function correctly. Social media
                  features and widgets are hosted by a third party or hosted
                  directly on Caliber Card. The Company&apos;s privacy policy
                  governs your interactions with these features. For more
                  information on the technologies used by these social networks,
                  please refer to their specific privacy notices. We have
                  provided links to the most used social networks below for easy
                  reference:
                </p>

                <ul className="list-service-our">
                  <li>
                    <strong>Facebook : </strong>
                    <Link to="https://www.facebook.com/about/privacy/">
                      https://www.facebook.com/about/privacy/
                    </Link>
                  </li>
                  <li>
                    <strong>Twitter : </strong>
                    <Link to="https://twitter.com/privacy">
                      https://twitter.com/privacy
                    </Link>
                  </li>
                  <li>
                    <strong>YouTube : </strong>
                    <Link to="https://policies.google.com/privacy">
                      https://policies.google.com/privacy
                    </Link>
                  </li>
                  <li>
                    <strong>Instagram : </strong>
                    <Link to="https://help.instagram.com/155833707900388">
                      https://help.instagram.com/155833707900388
                    </Link>
                  </li>
                  <li>
                    <strong>Twitch : </strong>
                    <Link to="https://www.twitch.tv/p/legal/privacy-notice/">
                      https://www.twitch.tv/p/legal/privacy-notice/
                    </Link>
                  </li>
                </ul>

                <h6>How We Use Your Information</h6>
                <p>We process your information to:</p>
                <ul className="browser">
                  <li>
                    verify your identity to comply with US federal, state, and
                    international laws
                  </li>
                  <li>allow you to sign in to your account </li>
                  <li>process payments and payouts </li>
                  <li>send merchandise to your shipping address</li>
                  <li>
                    send you emails relevant to your usage, as controlled by
                    your email preferences
                  </li>
                  <li>reply to your questions</li>
                  <li>
                    provide you with existing and enhanced services related to
                    your Caliber Card account
                  </li>
                  <li>spotlight and celebrate Third-party entities</li>
                  <li>
                    market Caliber Card products and services to you or to
                    audiences similar to you based on your networks and common
                    factors that others have with you unless you opt out, as
                    controlled by your email preferences
                  </li>
                  <li>
                    understand how you use the service and create better tools
                    for Third-party entities to serve Users
                  </li>
                  <li>
                    conduct research and development to improve Caliber Card and
                    develop future products
                  </li>
                  <li>prevent fraud and abuse on Caliber Card</li>
                  <li>
                    provide you with reasonable accommodation if you notify us
                    of a disability
                  </li>
                </ul>
                <h6>Information We Share with Third-party Entities</h6>
                <p>
                  By becoming a User of Caliber Card, you agree to have the
                  following information shared:
                </p>
                <ul className="browser">
                  <li>
                    your name and email address, and other profile information
                    you’ve provided
                  </li>
                  <li>
                    any messages you send Third-party entities through Caliber
                    Card
                  </li>
                  <li>your physical address, city, state, and country</li>
                  <li>
                    your phone number, when you signed up to support a User who
                    engages with you via text messages, when you are receiving a
                    benefit that requires shipping, or when you have signed up
                    for event notifications via text
                  </li>
                  <li>
                    all information about your pledge, including amount and
                    start date, but not your complete payment card information
                  </li>
                  <li>
                    some aggregated and anonymized data about how you use
                    Caliber Card that cannot be linked back to you or any
                    individual user
                  </li>
                </ul>
                <p>
                  Third-party entities agree to the terms of our Data Processing
                  Agreement (DPA) when a Caliber Card account is created. The
                  DPA is called the User Privacy Promise. The User Privacy
                  Promise is a User’s promise to protect the privacy of their
                  Users During and beyond their Caliber Card relationship.
                </p>

                <h6>Information We Share with Third Parties</h6>
                <p>
                  We never sell your information to third parties. We will only
                  share data with third parties, other than with Caliber Card
                  entities, under the following circumstances:
                </p>
                <ul className="browser">
                  <li>
                    With your consent, Third-party entities&apos; service
                    providers can deliver benefits, such as shipping packages to
                    you. Read these service providers&apos; privacy policies to
                    see how they use and share your data.
                  </li>
                  <li>
                    with our service providers, who are companies contractually
                    engaged with us to provide us with services such as order
                    fulfillment, email management, analyzing data trends, credit
                    card processing, multi-currency settlement solutions,
                    increasing our brand awareness and user engagement with
                    marketing initiatives, and fraud detection and prevention.
                    These companies may have access to your data to perform
                    their services and are obligated by contract to safeguard
                    any of your data they receive from us to the extent that
                    Caliber Card protects it.
                  </li>
                  <li>
                    We may disclose your data to protect Caliber Card&apos;s
                    security or integrity and the rights, property, or safety of
                    Caliber Card, its employees, users, or others if we believe
                    that disclosure is reasonably necessary to comply with a
                    law, regulation, or other valid legal processes (e.g.,
                    subpoenas or warrants served on Caliber Card). If we release
                    your data, we will do our best to promptly notify you by
                    email unless we are prohibited by law.
                  </li>
                  <li>
                    In connection with the sale, merger, bankruptcy, sale of
                    assets, or reorganization of the Company. We will notify you
                    if a different company receives your data. The promises in
                    this Policy apply to any data transferred to a new entity.
                  </li>
                </ul>

                <h6>Information Caliber Card Collects from Third-Party Apps</h6>
                <p>
                  When you create a Caliber Card account, you can elect to
                  connect your social account(s) (e.g., Twitter, Facebook,
                  Google, YouTube, Twitch) with Caliber Card, and we will
                  collect and store some social information from those
                  platforms, such as:
                </p>
                <ul className="browser">
                  <li>follower or subscriber counts</li>
                  <li>post or upload counts</li>
                  <li>View, like, and comment count.</li>
                </ul>
                <p>
                  This social information allows us to provide you with a better
                  Caliber Card experience and helps guide the future development
                  of Caliber Card. We use this data to:
                </p>
                <ul className="browser">
                  <li>
                    help third-party entities and users each other on Caliber
                    Card
                  </li>
                  <li>
                    assess how to make Third-party entities more successful on
                    Caliber Card
                  </li>
                  <li>analyze and describe our business</li>
                </ul>
                <h3>Your Preferences and Rights over Data</h3>
                <h6>Choosing Your Preferences</h6>
                <p>
                  The Settings link is located by clicking on your avatar or
                  profile at the top right of your screen after logging into
                  your Caliber Card account. Settings let you see your account
                  preferences. You can see and adjust your settings by viewing
                  your preferences and, if you wish, by changing your
                  selections.
                </p>

                <h6>Marketing Activities</h6>
                <p>
                  By agreeing to our terms of use, you expressly agree that
                  Caliber Card may:
                </p>
                <ul className="browser">
                  <li>
                    Send messages regarding your existing service or
                    enhancements related to your current service when you are
                    off our platform via email or text if you have signed up for
                    a service or event notification using text messages.{' '}
                  </li>
                  <li>
                    Send you messages related to services we provide that are
                    separate from your existing service via email, from which
                    you may opt-out.
                  </li>
                  <li>
                    Market Caliber Card to you and audiences similar to you
                    based on your networks and common factors that others have
                    with you unless you opt-out.
                  </li>
                  <li>
                    Send you marketing emails or texts if you don&apos;t have a
                    Caliber Card account but have consented to receive such
                    messages, from which you may opt out.
                  </li>
                  <li>
                    Ask for demographic information to spotlight and celebrate
                    third-party entities.
                  </li>
                </ul>

                <h6>Opting out of Marketing</h6>
                <p>
                  You may object to using your data for marketing purposes at
                  any time, free of charge, when we collect your personal
                  information and in every marketing communication. By opting
                  out here, you may also object to using your data for marketing
                  upon creating your account or anytime after that. Opting out
                  will stop marketing emails. Please allow up to thirty (30)
                  days for your opt-out request to be processed. If you have an
                  account with Caliber Card, you will continue receiving
                  service-related emails and texts if you have opted in to
                  receive texts. You will also continue receiving
                  service-related benefits shipments to the designated delivery
                  address.
                </p>
                <p>
                  We will never sell your information to third parties without
                  your consent for marketing purposes.
                </p>

                <h6>
                  Accessing, Updating, or Exporting the Information in Your
                  Account
                </h6>
                <p>
                  You can access and update certain information associated with
                  your account on the settings pages.
                </p>

                <h6>Turning off Mobile Notifications</h6>
                <p>
                  If you download the Caliber Card App, you may receive
                  notifications on your mobile device. These can be turned off
                  in the App settings.
                </p>
                <h6>Verification of Requests</h6>
                <p>
                  Users maintain password-protected accounts with Caliber Card.
                  If you submit a request to exercise your privacy rights, you
                  will be asked to verify the request by logging into your
                  Caliber Card account.
                </p>
                <p>
                  If we cannot verify your request, we reserve the right to deny
                  it for your protection.
                </p>
                <h3>Exercising Your Data Rights</h3>
                <p>
                  You can exercise rights over your data on Caliber Card in the
                  following ways:
                </p>

                <ul className="browser">
                  <li>
                    You can access, review, modify, and update your data by
                    logging into your account at Caliber Card and entering your
                    account settings.
                  </li>
                  <li>Could you view our privacy policy on this page?</li>
                  <li>
                    I was deleting a previously disabled account by emailing
                    Caliber Card at privacy@CaliberCard.com.
                  </li>
                  <li>
                    Opt out of marketing by entering your email address at this.
                  </li>
                  <li>
                    For data-related issues, go to FAQ support online or call
                    the Company&apos;s U.S. toll-free phone at{' '}
                    <strong>+1 (888) 972-7553</strong>.
                  </li>
                </ul>

                <p>
                  If you cannot log into your account and recover your account
                  with a password reset to lodge your privacy request, you may
                  contact privacy@CaliberCard.com.We reserve the right to
                  decline your access to or recover your account at our
                  discretion to prevent an unauthorized takeover.
                </p>

                <h6>Account Holders with Disabilities</h6>
                <p>
                  Caliber Card is committed to accessibility for its account
                  holders with disabilities. If you have difficulty accessing
                  your account, please email us at accessibility@CaliberCard.com
                  for assistance.{' '}
                </p>
                <h6>Contacting our Data Protection Officers</h6>
                <p>
                  If you have privacy concerns or questions, contact our US Data
                  Protection Officer (DPO) at{' '}
                  <strong>privacy@CaliberCard.com.</strong>
                </p>
                <p>
                  You may also mail a letter to the DPO with your questions. For
                  U.S.-based users, mail to Caliber Card Privacy Team, 2
                  Riverway, Suite 1750, Houston, TX, 77056
                </p>

                <h6>Establishing an Authorized Agent</h6>
                <p>
                  You may designate an authorized agent to request on your
                  behalf. To select an authorized agent to request on your
                  behalf, you must provide a valid power of attorney, the
                  requester’s valid government ID, and the authorized agent’s
                  valid government ID. Please get in touch with
                  privacy@ShotPro.com with your request. To protect your
                  information from account takeover, we may decline access to or
                  recover your account at our discretion.
                </p>
                <h6>Prohibition against Discrimination</h6>
                <p>
                  We do not discriminate against users who exercise their
                  privacy rights.
                </p>
                <h6>Our Data Retention Period</h6>
                <p>
                  We retain your account information for ten years after it is
                  last active unless you delete it or request us to delete it.
                  We may continue to maintain some knowledge even after you
                  delete your account if we are required to do so to comply with
                  various laws.
                </p>
                <h6>Security</h6>
                <p>
                  We value the security of your personal information and follow
                  industry standards to protect it. You can learn more on the{' '}
                  <Link to="https://therangenetwork.com/security-policy">
                    Security Policy
                  </Link>{' '}
                  page.
                </p>
                <h6>Children</h6>
                <p>
                  Caliber Card is not directed at children under 13 who may not
                  create an account or otherwise use Caliber Card.
                </p>
                <h6>Changes</h6>
                <p>
                  We may sometimes change this Policy. Suppose we make material
                  changes that adversely affect your rights under this Policy.
                  In that case, we will inform you by posting an announcement on
                  the site or emailing you in advance of the changes coming into
                  effect. Continuing to use Caliber Card after a change to this
                  Policy means you accept the new Policy. If you have any
                  questions, please email privacy@CaliberCard.com.
                </p>
                <p>
                  Effective immediately for existing users and users joining
                  Caliber Card on or after <i>January 15, 2025</i>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Privacy;
